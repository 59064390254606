/* eslint-disable operator-linebreak */
import { StyledFlexRow } from 'common/styles/common.styles';
import React, { useState } from 'react';
import { MonitoredAmm, MONITORED_AMMS } from 'constants/constants';
import { ChainId, SUPPORTED_NETWORKS, SupportedNetwork } from '@ichidao/ichi-sdk/dist/src/crypto/networks';
import Select from 'react-select';

type Props = {
  handleClick: (btn: MonitoredAmm) => void;
  currAmm: MonitoredAmm;
  isHidden?: boolean;
};

const ChainSelector: React.FC<Props> = (props) => {
  const [selectedChain, setSelectedChain] = useState(SUPPORTED_NETWORKS[props.currAmm.chain]);
  const [tab, setTab] = useState(props.currAmm);

  if (props.isHidden){
    return (<></>);
  }
  const getChainName = (n: SupportedNetwork) => {
    return n.name;
  };
  const getChainSymbol = (c: SupportedNetwork) => {
    return c.name;
  };
  const onChainChange = (c: Nullable<SupportedNetwork>) => {
    setSelectedChain(c || SUPPORTED_NETWORKS[props.currAmm.chain]);
    const newAmm = MONITORED_AMMS.filter((obj) => (obj.chain === (c?.chainId || ChainId.Mainnet)))[0].amms[0];
    setTab(newAmm);
    props.handleClick(newAmm);
  }
  const getName = (c: MonitoredAmm) => {
    return c.name;
  };
  const getSymbol = (c: MonitoredAmm) => {
    return c.name;
  };
  const onChange = (amm: Nullable<MonitoredAmm>) => {
    setTab(amm || props.currAmm);
    props.handleClick(amm || MONITORED_AMMS[ChainId.Mainnet].amms[0]);
  }

  let networks: SupportedNetwork[] = [];
  Object.keys(SUPPORTED_NETWORKS).map((key) => networks.push(SUPPORTED_NETWORKS[key]));
  networks = networks.filter((n) => (n.env === 'mainnet' || n.chainId === 763373) && n.chainId !== ChainId.Avalanche)
    .sort((a, b) => {return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1});

  return (
    <StyledFlexRow justifyContent='left' gap='10px'>
      Chain: 
      <Select<SupportedNetwork>
        className="basic-single w-52"
        classNamePrefix="vault-select"
        defaultValue={selectedChain}
        value={selectedChain}
        getOptionLabel={getChainName}
        getOptionValue={getChainSymbol}
        // isDisabled={props.isLoading}
        // isLoading={props.isLoading}
        name={tab.name}
        isClearable={false}
        options={networks}
        // components={{
        //   Option: TokenOption,
        //   SingleValue: TokenSingleValue,
        // }}
        onChange={onChainChange}
      />
      Dex: 
      <Select<MonitoredAmm>
        className="basic-single w-52"
        classNamePrefix="vault-select"
        defaultValue={tab}
        value={tab}
        getOptionLabel={getName}
        getOptionValue={getSymbol}
        // isDisabled={props.isLoading}
        // isLoading={props.isLoading}
        name={tab.name}
        isClearable={false}
        options={MONITORED_AMMS
          .filter((chainSet) => chainSet.chain === (selectedChain?.chainId || ChainId.Mainnet))[0].amms}
        // components={{
        //   Option: TokenOption,
        //   SingleValue: TokenSingleValue,
        // }}
        onChange={onChange}
      />
    </StyledFlexRow>
  );
};

export default ChainSelector;
